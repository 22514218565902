export const items = [
 {
  id: 1,
  category: "mobiles",
  title: "Samsung Galaxy S24 Ultra 5G AI Smartphone (Titanium Gray, 12GB, 1TB Storage)",
  imgSrc: "https://m.media-amazon.com/images/I/81vxWpPpgNL._AC_UY218_.jpg",
  description: "Samsung Galaxy S24 Ultra 5G AI Smartphone (Titanium Gray, 12GB, 1TB Storage)",
  price: "159999",
  rating: "3",
 },
 {
  id: 2,
  category: "mobiles",
  title: "OnePlus Nord CE4 Lite 5G (Super Silver, 8GB RAM, 128GB Storage)",
  imgSrc: "https://m.media-amazon.com/images/I/61Io5-ojWUL._AC_UY218_.jpg",
  description: "OnePlus Nord CE4 Lite 5G (Super Silver, 8GB RAM, 128GB Storage)",
  price: "19999",
  rating: "3.5",
 },
 {
  id: 3,
  category: "mobiles",
  title: "Redmi 13 5G, Hawaiian Blue, 6GB+128GB | India Debut SD 4 Gen 2 AE | 108MP Pro Grade Camera | 6.79in Largest Display in Segment",
  imgSrc: "https://m.media-amazon.com/images/I/81weRj535kL._AC_UY218_.jpg",
  description: "Redmi 13 5G, Hawaiian Blue, 6GB+128GB | India Debut SD 4 Gen 2 AE | 108MP Pro Grade Camera | 6.79in Largest Display in Segment",
  price: "13999",
  rating: "4",
 },
 {
  id: 4,
  category: "mobiles",
  title: "POCO M6 5G (Galactic Black, 8GB RAM, 256GB Storage)",
  imgSrc: "https://m.media-amazon.com/images/I/71b0T9mkV3L._AC_UY218_.jpg",
  description: "POCO M6 5G (Galactic Black, 8GB RAM, 256GB Storage)",
  price: "10999",
  rating: "5",
 },
 {
  id: 5,
  category: "mobiles",
  title: "Redmi 13C (Starshine Green, 6GB RAM, 128GB Storage) | Powered by 4G MediaTek Helio G85 | 90Hz Display | 50MP AI Triple Camera",
  imgSrc: "https://m.media-amazon.com/images/I/71hj88T5aBL._AC_UY218_.jpg",
  description: "Redmi 13C (Starshine Green, 6GB RAM, 128GB Storage) | Powered by 4G MediaTek Helio G85 | 90Hz Display | 50MP AI Triple Camera",
  price: "8499",
  rating: "3",
 },
 {
  id: 6,
  category: "mobiles",
  title: "Realme NARZO N63 (Twilight Purple,4GB RAM+64GB Storage) 45W Fast Charge | 5000mAh Durable Battery | 7.74mm Ultra Slim | 50MP AI Camera | AI Boost",
  imgSrc: "https://m.media-amazon.com/images/I/71ej8uLNzCL._AC_UY218_.jpg",
  description: "Realme NARZO N63 (Twilight Purple,4GB RAM+64GB Storage) 45W Fast Charge | 5000mAh Durable Battery | 7.74mm Ultra Slim | 50MP AI Camera | AI Boost",
  price: "8498",
  rating: "4",
 },
 {
  id: 7,
  category: "mobiles",
  title: "POCO C65 (Matte Black 4GB RAM 128GB Storage)",
  imgSrc: "https://m.media-amazon.com/images/I/51PQfSwIqyL._AC_UY218_.jpg",
  description: "POCO C65 (Matte Black 4GB RAM 128GB Storage)",
  price: "6999",
  rating: "4",
 },
 {
  id: 8,
  category: "mobiles",
  title: "Redmi 13 5g Black Diamond 6GB 128GB",
  imgSrc: "https://m.media-amazon.com/images/I/81ndCd07RuL._AC_UY218_.jpg",
  description: "Redmi 13 5g Black Diamond 6GB 128GB",
  price: "13999",
  rating: "5",
 },
 {
  id: 9,
  category: "mobiles",
  title: "Samsung Galaxy M14 4G (Arctic Blue,4GB,64GB) | 50MP Triple Cam | 5000mAh Battery | Snapdragon 680 Processor | 2 Gen. OS Upgrade & 4 Year Security Update | 8GB RAM with RAM Plus | without Charger",
  imgSrc: "https://m.media-amazon.com/images/I/81juPhKSZJL._AC_UY218_.jpg",
  description: "Samsung Galaxy M14 4G (Arctic Blue,4GB,64GB) | 50MP Triple Cam | 5000mAh Battery | Snapdragon 680 Processor | 2 Gen. OS Upgrade & 4 Year Security Update | 8GB RAM with RAM Plus | without Charger",
  price: "8230",
  rating: "2.5",
 },
 {
  id: 10,
  category: "mobiles",
  title: "Lava O2 (Imperial Green, 8GB RAM, UFS 2.2 128GB Storage) |AG Glass Back|T616 Octacore Processor|18W fast charging|6.5 inch 90Hz Punch Hole Display|50MP AI Dual Camera|Upto 16GB expandable RAM",
  imgSrc: "https://m.media-amazon.com/images/I/51yudMdOb8L._AC_UY218_.jpg",
  description: "Lava O2 (Imperial Green, 8GB RAM, UFS 2.2 128GB Storage) |AG Glass Back|T616 Octacore Processor|18W fast charging|6.5 inch 90Hz Punch Hole Display|50MP AI Dual Camera|Upto 16GB expandable RAM",
  price: "7999",
  rating: "2",
 },
 {
  id: 11,
  category: "laptops",
  title: 'Acer Aspire Lite 12th Gen Intel Core i3-1215U Premium Metal Laptop (Windows 11 Home/8 GB RAM/512GB SSD) AL15-52, 39.62cm (15.6") Full HD Display, Metal Body, Steel Gray, 1.59 Kg',
  imgSrc: "https://m.media-amazon.com/images/I/71czGb00k5L._AC_UY218_.jpg",
  description: 'Acer Aspire Lite 12th Gen Intel Core i3-1215U Premium Metal Laptop (Windows 11 Home/8 GB RAM/512GB SSD) AL15-52, 39.62cm (15.6") Full HD Display, Metal Body, Steel Gray, 1.59 Kg',
  price: "29990",
  rating: "3",
 },
 {
  id: 12,
  category: "laptops",
  title: 'Acer Aspire Lite 12th Gen Intel Core i7-1255U Premium Metal Laptop (Windows 11 Home/16 GB RAM/512 GB SSD) AL15-52, 39.62cm (15.6") Full HD Display, Metal Body, Steel Gray, 1.59 Kg',
  imgSrc: "https://m.media-amazon.com/images/I/61C0ewGxKJL._AC_UY218_.jpg",
  description: 'Acer Aspire Lite 12th Gen Intel Core i7-1255U Premium Metal Laptop (Windows 11 Home/16 GB RAM/512 GB SSD) AL15-52, 39.62cm (15.6") Full HD Display, Metal Body, Steel Gray, 1.59 Kg',
  price: "51680",
  rating: "4",
 },
 {
  id: 13,
  category: "laptops",
  title: "HP Laptop 15s, 12th Gen Intel Core i3, 15.6-inch (39.6 cm), 8GB DDR4, 512GB SSD, Thin & Light, Dual Speakers (Win 11, MSO 2021, Silver, 1.69 kg), fq5007TU / FQ5327TU",
  imgSrc: "https://m.media-amazon.com/images/I/71geuDTcr-L._AC_UY218_.jpg",
  description: "HP Laptop 15s, 12th Gen Intel Core i3, 15.6-inch (39.6 cm), 8GB DDR4, 512GB SSD, Thin & Light, Dual Speakers (Win 11, MSO 2021, Silver, 1.69 kg), fq5007TU / FQ5327TU",
  price: "35999",
  rating: "5",
 },
 {
  id: 14,
  category: "laptops",
  title: 'Acer Aspire Lite 12th Gen Intel Core i3-1215U Premium Metal Laptop (Windows 11 Home/8 GB RAM/512GB SSD) AL15-52, 39.62cm (15.6") Full HD Display, Metal Body, Steel Gray, 1.59 Kg',
  imgSrc: "https://m.media-amazon.com/images/I/71czGb00k5L._AC_UY218_.jpg",
  description: 'Acer Aspire Lite 12th Gen Intel Core i3-1215U Premium Metal Laptop (Windows 11 Home/8 GB RAM/512GB SSD) AL15-52, 39.62cm (15.6") Full HD Display, Metal Body, Steel Gray, 1.59 Kg',
  price: "29990",
  rating: "4.5",
 },
 {
  id: 15,
  category: "laptops",
  title: "HP Laptop 15s, AMD Ryzen 3 5300U, 15.6-inch (39.6 cm), FHD, 8GB DDR4, 512GB SSD, AMD Radeon graphics, Thin & light, Dual speakers (Win 11, MSO 2019, Silver, 1.69 kg), eq2143AU",
  imgSrc: "https://m.media-amazon.com/images/I/71-oBEGEnOL._AC_UY218_.jpg",
  description: "HP Laptop 15s, AMD Ryzen 3 5300U, 15.6-inch (39.6 cm), FHD, 8GB DDR4, 512GB SSD, AMD Radeon graphics, Thin & light, Dual speakers (Win 11, MSO 2019, Silver, 1.69 kg), eq2143AU",
  price: "29990",
  rating: "3.5",
 },
 {
  id: 16,
  category: "laptops",
  title: 'Dell [Smartchoice] 15 Thin & Light Laptop, 12th Gen Intel Core i3-1215U Processor/8GB/512GB SSD/Intel UHD Graphics/15.6"(39.62cm) FHD/Windows 11 + MSO"21/15 Month McAfee/Spill-Resistant KB/Grey/1.48kg',
  imgSrc: "https://m.media-amazon.com/images/I/71TcPkUibVL._AC_UY218_.jpg",
  description: 'Dell [Smartchoice] 15 Thin & Light Laptop, 12th Gen Intel Core i3-1215U Processor/8GB/512GB SSD/Intel UHD Graphics/15.6"(39.62cm) FHD/Windows 11 + MSO"21/15 Month McAfee/Spill-Resistant KB/Grey/1.48kg',
  price: "35990",
  rating: "4.5",
 },
 {
  id: 17,
  category: "laptops",
  title: "HP Laptop 15s, 12th Gen Intel Core i5-1235U, 15.6-inch (39.6 cm), FHD, 16GB DDR4, 512GB SSD, Intel Iris Xe graphics, 720p HD camera, Backlit KB, Thin & Light (Win 11, Silver, 1.69 kg), fy5009TU",
  imgSrc: "https://m.media-amazon.com/images/I/71BmqssGuRL._AC_UY218_.jpg",
  description: "HP Laptop 15s, 12th Gen Intel Core i5-1235U, 15.6-inch (39.6 cm), FHD, 16GB DDR4, 512GB SSD, Intel Iris Xe graphics, 720p HD camera, Backlit KB, Thin & Light (Win 11, Silver, 1.69 kg), fy5009TU",
  price: "50990",
  rating: "3",
 },
 {
  id: 18,
  category: "laptops",
  title: "ZEBRONICS PRO Series Y NBC 2S, Intel Core 11th Gen i5 Processor 16GB RAM & 512GB SSD Laptop, 15.6 Inch (39.6 CM), IPS Display, Ultra Slim, 38.5Wh Large Battery, Windows 11, Silver, 1.76 Kg",
  imgSrc: "https://m.media-amazon.com/images/I/71s2KUCAUsL._AC_UY218_.jpg",
  description: "ZEBRONICS PRO Series Y NBC 2S, Intel Core 11th Gen i5 Processor 16GB RAM & 512GB SSD Laptop, 15.6 Inch (39.6 CM), IPS Display, Ultra Slim, 38.5Wh Large Battery, Windows 11, Silver, 1.76 Kg",
  price: "26740",
  rating: "4",
 },
 {
  id: 19,
  category: "laptops",
  title: "ZEBRONICS PRO Series Z NBC 3S, Intel Core 12th Gen i3 Processor 8GB RAM & 512GB SSD Laptop, 15.6 Inch (39.6CM), IPS 180° Display, Dolby Atmos, 38.5Wh Large Battery, Windows 11, Midnight Blue,1.76 Kg",
  imgSrc: "https://m.media-amazon.com/images/I/710kMhJg2lL._AC_UY218_.jpg",
  description: "ZEBRONICS PRO Series Z NBC 3S, Intel Core 12th Gen i3 Processor 8GB RAM & 512GB SSD Laptop, 15.6 Inch (39.6CM), IPS 180° Display, Dolby Atmos, 38.5Wh Large Battery, Windows 11, Midnight Blue,1.76 Kg",
  price: "24990",
  rating: "3.5",
 },
 {
  id: 20,
  category: "laptops",
  title: "Apple MacBook Air Laptop M1 chip, 13.3-inch/33.74 cm Retina Display, 8GB RAM, 256GB SSD Storage, Backlit Keyboard, FaceTime HD Camera, Touch ID. Works with iPhone/iPad; Space Grey",
  imgSrc: "https://m.media-amazon.com/images/I/71jG+e7roXL._AC_UY218_.jpg",
  description: "Apple MacBook Air Laptop M1 chip, 13.3-inch/33.74 cm Retina Display, 8GB RAM, 256GB SSD Storage, Backlit Keyboard, FaceTime HD Camera, Touch ID. Works with iPhone/iPad; Space Grey",
  price: "68990",
  rating: "5",
 },
 {
  id: 21,
  category: "tv",
  title: "LIMEBERRY 43 Inch TV Frameless Full HD Smart QLED Led Television, Android 11 with Eye safe mode, Built-in Dolbe Sound Speakers,Dedicated shot hot keys Remote, Black (LB431CN6)",
  imgSrc: "https://m.media-amazon.com/images/I/71Uta8sW-mL._AC_UY218_.jpg",
  description: "LIMEBERRY 43 Inch TV Frameless Full HD Smart QLED Led Television, Android 11 with Eye safe mode, Built-in Dolbe Sound Speakers,Dedicated shot hot keys Remote, Black (LB431CN6)",
  price: "14899",
  rating: "2.5",
 },
 {
  id: 22,
  category: "tv",
  title: "TCL 139 cm (55 inches) 4K Ultra HD Smart QLED Google TV 55P71B Pro (Black)",
  imgSrc: 'https://m.media-amazon.com/images/I/81ZO3UviJKL._AC_UY218_.jpg',
  description: "TCL 139 cm (55 inches) 4K Ultra HD Smart QLED Google TV 55P71B Pro (Black)",
  price: "41990",
  rating: "4",
 },
 {
  id: 23,
  category: "tv",
  title: "Samsung 80 cm (32 inches) HD Ready Smart LED TV UA32T4380AKXXL (Glossy Black)",
  imgSrc: "https://m.media-amazon.com/images/I/81f-zPL6MIL._AC_UY218_.jpg",
  description: "Samsung 80 cm (32 inches) HD Ready Smart LED TV UA32T4380AKXXL (Glossy Black)",
  price: "13989 ",
  rating: "4",
 },
 {
  id: 24,
  category: "tv",
  title: "MI 80 cm (32 inches) A Series HD Ready Smart Google LED TV L32MA-AIN (Black)",
  imgSrc: "https://m.media-amazon.com/images/I/81u+lUSZRDL._AC_UY218_.jpg",
  description: "MI 80 cm (32 inches) A Series HD Ready Smart Google LED TV L32MA-AIN (Black)",
  price: "12499",
  rating: "3",
 },
 {
  id: 25,
  category: "tv",
  title: "Redmi 80 cm (32 inches) F Series HD Ready Smart LED Fire TV L32MA-FVIN (Black)",
  imgSrc: "https://m.media-amazon.com/images/I/71MwDnn-ZaL._AC_UY218_.jpg",
  description: "Redmi 80 cm (32 inches) F Series HD Ready Smart LED Fire TV L32MA-FVIN (Black)",
  price: "11499",
  rating: "5",
 },
 {
  id: 26,
  category: "tv",
  title: "Sony Bravia 139 cm (55 inches) 4K Ultra HD Smart LED Google TV KD-55X74L (Black)",
  imgSrc: "https://m.media-amazon.com/images/I/81MRU+3RJLL._AC_UY218_.jpg",
  description: "Sony Bravia 139 cm (55 inches) 4K Ultra HD Smart LED Google TV KD-55X74L (Black)",
  price: "54990 ",
  rating: "5",
 },
 {
  id: 27,
  category: "tv",
  title: "Acer 100 cm (40 inches) Advanced I Series Full HD Smart LED Google TV AR40GR2841FDFL (Black)",
  imgSrc: "https://m.media-amazon.com/images/I/5126gXnEjFL._AC_UY218_.jpg",
  description: "Acer 100 cm (40 inches) Advanced I Series Full HD Smart LED Google TV AR40GR2841FDFL (Black)",
  price: "16499",
  rating: "3.5",
 },
 {
  id: 28,
  category: "tv",
  title: "VW 80 cm (32 inches) Frameless Series HD Ready Android Smart LED TV VW32S (Black)",
  imgSrc: "https://m.media-amazon.com/images/I/717oSOB4hCL._AC_UY218_.jpg",
  description: "VW 80 cm (32 inches) Frameless Series HD Ready Android Smart LED TV VW32S (Black)",
  price: "7499",
  rating: "3",
 },
 {
  id: 29,
  category: "tv",
  title: "Samsung 108 cm (43 inches) D Series Crystal 4K Vivid Ultra HD Smart LED TV UA43DUE70BKLXL (Black)",
  imgSrc: "https://m.media-amazon.com/images/I/81nikv9C6lL._AC_UY218_.jpg",
  description: "Samsung 108 cm (43 inches) D Series Crystal 4K Vivid Ultra HD Smart LED TV UA43DUE70BKLXL (Black)",
  price: "28990",
  rating: "5",
 },
 {
  id: 30,
  category: "tv",
  title: "Acer 80 cm (32 inches) V Series HD Ready Smart QLED Google TV AR32GR2841VQD (Black)",
  imgSrc: "https://m.media-amazon.com/images/I/71RhzhBtsFL._AC_UY218_.jpg",
  description: "Acer 80 cm (32 inches) V Series HD Ready Smart QLED Google TV AR32GR2841VQD (Black)",
  price: "13999",
  rating: "5",
 },
 {
  id: 31,
  category: "tablets",
  title: "OnePlus Pad Go 28.85cm (11.35 inch) 2.4K 7:5 ratio ReadFit Eye Care LCD Display, Dolby Atmos Quad Speakers,Wi-Fi Only, 8GB RAM 128 GB ROM Expandable Up-to 1TB, Twin Mint Colour",
  imgSrc: "https://m.media-amazon.com/images/I/51oj5gE7P+L._AC_UY218_.jpg",
  description: "OnePlus Pad Go 28.85cm (11.35 inch) 2.4K 7:5 ratio ReadFit Eye Care LCD Display, Dolby Atmos Quad Speakers,Wi-Fi Only, 8GB RAM 128 GB ROM Expandable Up-to 1TB, Twin Mint Colour",
  price: "17999",
  rating: "4.5",
 },
 {
  id: 32,
  category: "tablets",
  title: "HONOR Pad X8 25.65 cm (10.1 inch) FHD Display, 3GB RAM, 32GB Storage, Mediatek MT8786, Android 12, Tuv Certified Eye Protection, Up to 14 Hours Battery WiFi Tablet, Blue Hour",
  imgSrc: "https://m.media-amazon.com/images/I/616otiZuSIL._AC_UY218_.jpg",
  description: "HONOR Pad X8 25.65 cm (10.1 inch) FHD Display, 3GB RAM, 32GB Storage, Mediatek MT8786, Android 12, Tuv Certified Eye Protection, Up to 14 Hours Battery WiFi Tablet, Blue Hour",
  price: "8999",
  rating: "3.5",
 },
 {
  id: 33,
  category: "tablets",
  title: "OnePlus Pad Go 28.85Cm (11.35 Inch) 2.4K 7:5 Ratio Readfit Eye Care LCD Display,Dolby Atmos Quad Speakers,4G LTE(Calling) + Wi-Fi Connectivity Tablet,8Gb Ram 128 Gb Storage Expandable Up-to 1Tb,Green",
  imgSrc: "https://m.media-amazon.com/images/I/51oj5gE7P+L._AC_UY218_.jpg",
  description: "OnePlus Pad Go 28.85Cm (11.35 Inch) 2.4K 7:5 Ratio Readfit Eye Care LCD Display,Dolby Atmos Quad Speakers,4G LTE(Calling) + Wi-Fi Connectivity Tablet,8Gb Ram 128 Gb Storage Expandable Up-to 1Tb,Green",
  price: "19999",
  rating: "4",
 },
 {
  id: 34,
  category: "tablets",
  title: "Samsung Galaxy Tab S6 Lite 26.31 cm (10.4 inch), S-Pen in Box, Slim and Light, Dolby Atmos Sound, 4 GB RAM, 64 GB ROM, Wi-Fi Tablet, Gray Upto INR 5000 Bank Discount",
  imgSrc: "https://m.media-amazon.com/images/I/61vsK7h3zVL._AC_UY218_.jpg",
  description: "Samsung Galaxy Tab S6 Lite 26.31 cm (10.4 inch), S-Pen in Box, Slim and Light, Dolby Atmos Sound, 4 GB RAM, 64 GB ROM, Wi-Fi Tablet, Gray Upto INR 5000 Bank Discount",
  price: "23990",
  rating: "3",
 },
 {
  id: 35,
  category: "tablets",
  title: "Samsung Galaxy Tab S9 FE 27.69 cm (10.9 inch) Display, RAM 6 GB, ROM 128 GB Expandable, S Pen in-Box, Wi-Fi, IP68 Tablet, Gray Upto INR 7000 Bank Discount",
  imgSrc: "https://m.media-amazon.com/images/I/61M4gkurLUL._AC_UY218_.jpg",
  description: "Samsung Galaxy Tab S9 FE 27.69 cm (10.9 inch) Display, RAM 6 GB, ROM 128 GB Expandable, S Pen in-Box, Wi-Fi, IP68 Tablet, Gray Upto INR 7000 Bank Discount",
  price: "36999",
  rating: "5",
 },
 {
  id: 36,
  category: "tablets",
  title: "Lenovo Tab Plus with Octa JBL Hi-Fi Speakers| 8 GB RAM, 128 GB ROM| 11.5 Inch, 2K, 90 Hz Refresh| Wi-Fi Tablet| Android 14| 45 W Fast Charger| Built-in Kickstand| Color: Luna Grey",
  imgSrc: "https://m.media-amazon.com/images/I/61mKKrl80NL._AC_UY218_.jpg",
  description: "Lenovo Tab Plus with Octa JBL Hi-Fi Speakers| 8 GB RAM, 128 GB ROM| 11.5 Inch, 2K, 90 Hz Refresh| Wi-Fi Tablet| Android 14| 45 W Fast Charger| Built-in Kickstand| Color: Luna Grey",
  price: "20999",
  rating: "4",
 },
 {
  id: 37,
  category: "tablets",
  title: "HONOR Pad X9 with Free Flip-Cover 11.5-inch (29.21 cm) 2K Display, Snapdragon 685, 7GB (4GB+3GB RAM Turbo), 128GB Storage, 6 Speakers, Up-to 13 Hours Battery, Android 13, WiFi Tablet, Metal Body, Gray",
  imgSrc: "https://m.media-amazon.com/images/I/61cG9RGN9xL._AC_UY218_.jpg",
  description: "HONOR Pad X9 with Free Flip-Cover 11.5-inch (29.21 cm) 2K Display, Snapdragon 685, 7GB (4GB+3GB RAM Turbo), 128GB Storage, 6 Speakers, Up-to 13 Hours Battery, Android 13, WiFi Tablet, Metal Body, Gray",
  price: "15999",
  rating: "3.5",
 },
 {
  id: 38,
  category: "tablets",
  title: "Apple iPad (10th generation): with A14 Bionic chip, 27.69 cm (10.9″) Liquid Retina display, 64GB, Wi-Fi 6, 12MP front/12MP back camera, Touch ID, all-day battery life – Blue",
  imgSrc: "https://m.media-amazon.com/images/I/61uA2UVnYWL._AC_UY218_.jpg",
  description: "Apple iPad (10th generation): with A14 Bionic chip, 27.69 cm (10.9″) Liquid Retina display, 64GB, Wi-Fi 6, 12MP front/12MP back camera, Touch ID, all-day battery life – Blue",
  price: "31999",
  rating: "3",
 },
 {
  id: 39,
  category: "tablets",
  title: "Xiaomi Pad 6| Qualcomm Snapdragon 870| Powered by HyperOS |144Hz Refresh Rate| 8GB, 256GB| 2.8K+ Display (11-inch/27.81cm) Tablet| Dolby Vision Atmos| Quad Speakers| Wi-Fi| Gray",
  imgSrc: "https://m.media-amazon.com/images/I/71LRY1j6UHL._AC_UY218_.jpg",
  description: "Xiaomi Pad 6| Qualcomm Snapdragon 870| Powered by HyperOS |144Hz Refresh Rate| 8GB, 256GB| 2.8K+ Display (11-inch/27.81cm) Tablet| Dolby Vision Atmos| Quad Speakers| Wi-Fi| Gray",
  price: "28999",
  rating: "4",
 },
 {
  id: 40,
  category: "tablets",
  title: "Redmi Pad SE| All day battery | Qualcomm Snapdragon 680| 90Hz Refresh Rate| 8GB, 128GB Tablet| FHD+ Display (11-inch/27.81cm)| Dolby Atmos| Quad Speakers| Wi-Fi| Gray",
  imgSrc: "https://m.media-amazon.com/images/I/710WMfH99HL._AC_UY218_.jpg",
  description: "Redmi Pad SE| All day battery | Qualcomm Snapdragon 680| 90Hz Refresh Rate| 8GB, 128GB Tablet| FHD+ Display (11-inch/27.81cm)| Dolby Atmos| Quad Speakers| Wi-Fi| Gray",
  price: "14999",
  rating: "5",
 },
];
